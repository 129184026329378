import { getPdpQuoteId, getQuoteId } from '@coverright/utils';
import React from 'react';
import {
  AppActionTypes,
  AppContext,
  PdpQuoteContext,
  QuoteContext,
} from '@coverright/shared/contexts';
import { ActionType, Coverage, DrugOffersContext } from '../lib';
import {
  convertPreferredDrugInput,
  PreferredDrugOutput,
} from '@coverright/widgets';

export default function useSaveQuote() {
  const {
    state: { plan },
    dispatch,
  } = React.useContext(DrugOffersContext);
  const [{ state }, appDispatch] = React.useContext(AppContext);
  const {
    save: maQuoteSave,
    saveClientInfo: maSaveClientInfo,
    saveClientInfoLoading: maClientInfoLoading,
    saveLoading: maLoading,
  } = React.useContext(QuoteContext);
  const {
    save: pdpQuoteSave,
    saveClientInfo: pdpSaveClientInfo,
    saveClientInfoLoading: pdpClientInfoLoading,
    saveLoading: pdpLoading,
  } = React.useContext(PdpQuoteContext);

  const savePlanToAppState = () => {
    appDispatch({
      type: AppActionTypes.SAVE_STATE,
      payload: {
        stateKey: 'additionalInfo',
        value: [
          ...(state.additionalInfo || []).filter(
            (line) => !line.includes('Added plan to drug calculator - ')
          ),
          `Added plan to drug calculator - "${plan}"`,
        ],
      },
    });
  };

  const saveQuote = async (
    type: Coverage,
    zip: string,
    countyName: string,
    drugs: PreferredDrugOutput[]
  ) => {
    if (type === Coverage.MA) {
      return maQuoteSave({
        variables: {
          data: {
            id: getQuoteId(),
            zip,
            county: countyName,
          },
        },
      }).then((response) => {
        if (response.data) {
          const { maQuoteID, clientId } = response.data.saveMedicareQuote;
          dispatch({
            type: ActionType.SET_QUOTE_ID,
            payload: maQuoteID,
          });
          dispatch({
            type: ActionType.SET_CLIENT_ID,
            payload: clientId,
          });

          savePlanToAppState();

          return maSaveClientInfo({
            variables: {
              clientId,
              preferredDrugs: drugs.map(convertPreferredDrugInput),
            },
          });
        }
        return;
      });
    }
    if (type === Coverage.PDP) {
      return pdpQuoteSave({
        variables: {
          input: {
            id: getPdpQuoteId(),
            zip,
            countyName,
          },
        },
      }).then((response) => {
        if (response.data) {
          const { id, clientId } = response.data.savePdpQuote;
          dispatch({
            type: ActionType.SET_QUOTE_ID,
            payload: id,
          });
          dispatch({
            type: ActionType.SET_CLIENT_ID,
            payload: clientId,
          });

          savePlanToAppState();

          return pdpSaveClientInfo({
            variables: {
              clientId,
              preferredDrugs: drugs.map(convertPreferredDrugInput),
            },
          });
        }
        return;
      });
    }
    return;
  };

  return {
    saveQuote,
    loading:
      maLoading || pdpLoading || maClientInfoLoading || pdpClientInfoLoading,
  };
}
