import { SelectInput } from '@coverright/ui/inputs';
import {Box, MenuItem} from '@mui/material';
import { usePlans } from '../hooks/use-plans';
import {Coverage} from "../lib";

interface Props {
  zip?: string,
  countyName?: string,
  type?: Coverage,
  value: string,
  onChange: (value: string) => void,
  carrier?: string,
  hideBid?: boolean
}

export function PlanSelect(props: Props) {
  const { type, zip, countyName, onChange, value, carrier, hideBid } = props;
  const [plans, loading] = usePlans(type, zip, countyName, carrier);

  return <SelectInput label={'Plan name'}
                      hideTick
                      loading={loading}
                      className={'mb-0'}
                      placeholder={'Select plan'}
                      data-test={'plan'}
                      value={value}
                      disabled={!plans.length || loading}
                      onChange={(event) => {
                        onChange(event.target.value as string);
                      }}
  >
    {plans.map(p => <MenuItem key={p.bidId} value={p.bidId} data-test={'plan-select-item'}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1, pr: 3 }}>
        <Box component={'span'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{p.planName}</Box>
        {!hideBid && <Box sx={{color: 'text.secondary'}} component={'span'} className={'fs-12'}>{p.bidId}</Box>}
      </Box>
    </MenuItem>)}
  </SelectInput>;
}
