import React from 'react';

import { Box, Container, SxProps } from '@mui/material';

interface LayoutProps {
  slots?: {
    appBar?: React.JSX.Element,
    footer?: React.JSX.Element,
  };
  showSpots?: boolean;
}

export function Layout({slots, showSpots = true, children}: React.PropsWithChildren<LayoutProps>) {

  return <>
    {showSpots && <>
      <Box component={'img'} src={'assets/img/spot-1.svg'}
           sx={{ position: 'fixed', zIndex: -1, left: { xs: 'calc(50% - 450px)', md: 'calc(50% - 650px)' }, top: 20 }}/>
      <Box component={'img'} src={'assets/img/spot-2.svg'}
           sx={{ position: 'fixed', zIndex: -1, right: { xs: 'calc(50% - 460px)', md: 'calc(50% - 560px)' }, top: 340 }}/>
      <Box component={'img'} src={'assets/img/spot-3.svg'}
           sx={{ position: 'fixed', zIndex: -1, left: { xs: 'calc(50% - 550px)', md: 'calc(50% - 715px)' }, top: 710 }}/>
    </>}

    {slots?.appBar}
    <Container >
      {children}
    </Container>
    {slots?.footer}
  </>;
}

