import {Action, ActionType, DrugOffersState} from ".";

export const drugOffersReducer = (state: DrugOffersState, {type, payload}: Action): DrugOffersState => {
  const newState = {...state};

  switch (type) {
    case ActionType.SET_COVERAGE: {
      newState.coverage = payload;
    } break;

    case ActionType.SET_LOCATION: {
      newState.zip = payload.zip;
      newState.state = payload.state;
      newState.countyName = payload.countyName;
    } break;

    case ActionType.SET_CARRIER: {
      newState.carrier = payload;
    } break;

    case ActionType.SET_PLAN: {
      newState.plan = payload;
    } break;

    case ActionType.SET_DATA_IS_NOT_FULL: {
      newState.dataIsNotFull = payload;
    } break;

    case ActionType.SET_DRUGS: {
      newState.drugs = payload;
    } break;

    case ActionType.SET_QUOTE_ID: {
      newState.quoteId = payload;
    } break;

    case ActionType.SET_CLIENT_ID: {
      newState.clientId = payload;
    } break;

    default: {
      console.error('Unknown action');
    }
  }

  sessionStorage.setItem('drugOffersState', JSON.stringify(newState));

  return newState;
}
