import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import { initTrackEvent } from '@coverright/shared/analytics';
import { SnackbarProvider } from 'notistack';
import { environment } from './environments/environment';
import { Provider } from 'use-http'
import {KeycloakProvider} from "@coverright/shared/keycloak";

initTrackEvent('drug-optimizer');

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <Provider url={environment.userFlowRestUrl}>
      <BrowserRouter>
        <SnackbarProvider preventDuplicate anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
          <KeycloakProvider lateInit>
            <App />
          </KeycloakProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
