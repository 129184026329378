import { useNavigate } from 'react-router';
import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { PageRoutes } from '../router';
import { GetPlansYear } from '@coverright/utils';
import AgentCard from '../components/agent-card';
import OffersCtaCard from '../components/offers-cta-card';
import { ActionType, Coverage, DrugOffersContext } from '../lib';
import { DrugCostBreakdown, PlanType } from '@coverright/widgets';
import { CompanySelect } from '../components/company-select';
import { PlanSelect } from '../components/plan-select';

export default function Offers() {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(DrugOffersContext);
  const { zip, countyName, coverage, drugs, plan, quoteId, clientId, carrier } =
    state;

  if (!coverage) {
    navigate(PageRoutes.main);
  }

  if (!zip || !countyName) {
    navigate(PageRoutes.zip);
  }

  if (!carrier) {
    navigate(PageRoutes.plan);
  }

  if (!drugs.length) {
    navigate(PageRoutes.drugs);
  }

  const handleCarrierSelect = (payload: string) => {
    dispatch({
      type: ActionType.SET_PLAN,
      payload: '',
    });
    dispatch({
      type: ActionType.SET_CARRIER,
      payload,
    });
  };

  const handlePlanSelect = (payload: string) => {
    dispatch({
      type: ActionType.SET_PLAN,
      payload,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        gap: 3,
      }}
    >
      <Content>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: 3,
          }}
        >
          <div>
            <Button
              variant={'outlined'}
              startIcon={<img src={'/assets/img/arrow-left.svg'} />}
              onClick={() => navigate(PageRoutes.main)}
            >
              Update your information
            </Button>
          </div>

          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}
          >
            <CompanySelect
              value={carrier}
              onChange={handleCarrierSelect}
              type={coverage}
              zip={zip}
              countyName={countyName}
            />

            <PlanSelect
              value={plan}
              onChange={handlePlanSelect}
              type={coverage}
              zip={zip}
              hideBid
              countyName={countyName}
              carrier={carrier}
            />
          </Box>
        </Box>

        {plan && (
          <DrugCostBreakdown
            variant={'v3'}
            bidId={plan}
            zip={zip!}
            countyName={countyName!}
            planYear={GetPlansYear()}
            quoteId={quoteId}
            clientId={clientId}
            type={coverage === Coverage.MA ? PlanType.Ma : PlanType.Pdp}
          />
        )}
      </Content>

      <Side>
        <OffersCtaCard />
        <AgentCard />
      </Side>
    </Box>
  );
}

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  flex: 1,
});

const Side = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  [theme.breakpoints.up('lg')]: {
    maxWidth: 294,
  },
}));
