import { PropsWithChildren } from 'react';
import * as React from 'react';
import { Box, Card } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface Props extends PropsWithChildren<any> {
  maxWidth?: number,
  progress?: JSX.Element,
}

export function OnboardingProgressCard(props: Props) {

  return <Card sx={{
    p: { xs: 3, sm: 5 },
    width: 1,
    maxWidth: props.maxWidth || 822,
    m: 'auto',
    overflowX: 'hidden',
    transition: 'all 0.3s'
  }}>
    {props.progress}
    <Box sx={{ maxWidth: props.maxWidth || 742 }}>
      <Outlet />
    </Box>
  </Card>
}
