import { useNavigate } from 'react-router';
import React from 'react';
import {PageRoutes} from '../router';
import {Box, Button, Typography} from '@mui/material';
import {useLogEvent} from '@coverright/shared/analytics';
import {CompanySelect} from '../components/company-select';
import {PlanSelect} from '../components/plan-select';
import {V3InfoPanel} from '@coverright/ui/shared';
import {ActionType, DrugOffersContext} from "../lib";

export default function Plan() {
  const navigate = useNavigate();
  const {state: {coverage, carrier, plan, zip, countyName}, dispatch} = React.useContext(DrugOffersContext);
  const logEvent = useLogEvent();

  if (!zip || !countyName) {
    navigate(PageRoutes.zip)
  }

  const onSubmit = () => {
    dispatch({
      type: ActionType.SET_DATA_IS_NOT_FULL,
      payload: false
    })
    logEvent('Drug optimizer - select plan', {carrier, plan})
    navigate(PageRoutes.drugs)
  }

  const handleCarrierSelect = (payload: string) => {
    dispatch({
      type: ActionType.SET_PLAN,
      payload: ''
    })
    dispatch({
      type: ActionType.SET_CARRIER,
      payload
    })
  }

  const handlePlanSelect = (payload: string) => {
    dispatch({
      type: ActionType.SET_PLAN,
      payload
    })
  }

  const onSkipClick = () => {
    dispatch({
      type: ActionType.SET_DATA_IS_NOT_FULL,
      payload: true
    })
    navigate(PageRoutes.drugs)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: 1 }}>
      <Typography variant={'h3'} align={'center'}>Please provide the name of your current plan:</Typography>

      <CompanySelect value={carrier}
                     onChange={handleCarrierSelect}
                     type={coverage}
                     zip={zip}
                     countyName={countyName}
      />

      <PlanSelect value={plan}
                  onChange={handlePlanSelect}
                  type={coverage}
                  zip={zip}
                  countyName={countyName}
                  carrier={carrier}
      />

      <V3InfoPanel sx={{mt: '19px'}}
                   title={'Not sure?'}
                   text={'Check the card provided to you by your insurance carrier.  This will include the name of the insurance company and the specific name of the plan you are enrolled in.'} />


      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'outlined'} onClick={() => navigate(-1)}>Back</Button>
        <Button disabled={!plan}
                variant={'contained'}
                color={'primary'}
                onClick={onSubmit}>
          Continue
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant={'text'} color={'primary'} onClick={onSkipClick}>
          I can't find my plan
        </Button>
      </Box>
    </Box>
  )
}
