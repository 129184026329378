import { useNavigate } from 'react-router';
import React from 'react';
import { V3InfoPanel } from '@coverright/ui/shared';
import { Box, Button, Typography } from '@mui/material';
import { PageRoutes } from '../router';
import { ZipSelector } from '@coverright/features';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ActionType, DrugOffersContext } from '../lib';
import { useLogEvent } from '@coverright/shared/analytics';

export default function ZipForm() {
  const navigate = useNavigate();
  const {
    state: { coverage, state, zip, countyName },
    dispatch,
  } = React.useContext(DrugOffersContext);
  const logEvent = useLogEvent();

  const formik = useFormik({
    initialValues: { zip, countyName, state },
    validationSchema: yup.object(schemaFields),
    onSubmit: async (payload) => {
      if (!coverage) {
        navigate(PageRoutes.main);
      }
      if (formik?.isValid) {
        dispatch({
          type: ActionType.SET_LOCATION,
          payload,
        });
        logEvent('Drug optimizer - enter zip', { ...payload });
        navigate(PageRoutes.plan);
      }
    },
    validateOnChange: true,
  });

  if (!coverage) {
    navigate(PageRoutes.main);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: 1 }}>
      <Typography variant={'h3'} align={'center'}>
        Great! <br /> What is your current zip code?
      </Typography>

      <form onSubmit={formik.handleSubmit} className={'fill-width'}>
        <ZipSelector
          direction={'column'}
          acceptAllZips
          showZipNotification={false}
          zip={formik.values.zip}
          countyName={formik.values.countyName}
          onChange={(data) => formik.setValues({ ...formik.values, ...data })}
          errors={{
            zip: formik.touched.zip && formik.errors.zip,
            countyName: formik.touched.countyName && formik.errors.countyName,
          }}
        />

        <V3InfoPanel
          sx={{ mt: '19px', mb: '27px' }}
          title={'What for?'}
          text={'We need this in order to provide you accurate costs.'}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant={'outlined'} onClick={() => navigate(-1)} data-test='back-button' id='drug-cost-back-button' >
            Back
          </Button>
          <Button variant={'contained'} color={'primary'} type={'submit'} data-test='continue-button' id='drug-cost-continue-button'> 
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
}

const schemaFields: any = {
  zip: yup.string().required('Zip is required'),
  countyName: yup.string().required('County name is required'),
  state: yup.string(),
};
