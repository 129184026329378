import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { GetPlansYear } from '@coverright/utils';
import {Coverage} from "../lib";

interface Company {
  name: string,
  count: number,
}

export function useCompanies(type?: Coverage, zip?: string, countyName?: string): [Company[], boolean] {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [getCompanies, {loading}] = useLazyQuery(gql(type === 'MA' ? companiesQuery : pdpCompaniesQuery));

  React.useEffect(() => {
    if (zip && countyName) {
      getCompanies({
        variables: {
          filter: {
            zip, countyName, planYear: GetPlansYear()
          }
        }
      }).then(data => {
        setCompanies(data.data.companies.filter((c: Company) => c.count > 0));
      });
    }

  }, [zip, countyName]);

  return [companies, loading];
}

const companiesQuery = `
query($filter: PlansFilterInput!) {
    companies(filter: $filter) {
      name
      count
    }
}
`;

const pdpCompaniesQuery = `
query($filter: PdpPlansFilterInput!) {
    companies: pdpCompanies(filter: $filter) {
      name
      count
    }
}
`;
