import { useNavigate } from 'react-router';
import React from 'react';
import { PageRoutes } from '../router';
import { V3InfoPanel } from '@coverright/ui/shared';
import {
  convertPreferredDrugInput,
  PreferredDrugOutput,
  DrugSelection,
} from '@coverright/widgets';
import { useLogEvent } from '@coverright/shared/analytics';
import { Box, Button, styled, Typography } from '@mui/material';
import { ActionType, DrugOffersContext } from '../lib';
import { LoadingButton } from '@mui/lab';
import useSaveQuote from '../hooks/use-save-quote';

export default function Drugs() {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(DrugOffersContext);
  const { drugs, zip, countyName, plan, dataIsNotFull, coverage } = state;
  const logEvent = useLogEvent();
  const { saveQuote, loading } = useSaveQuote();

  if (!coverage) {
    navigate(PageRoutes.main);
  }

  if (!zip || !countyName) {
    navigate(PageRoutes.zip);
  }

  if (!plan && !dataIsNotFull) {
    navigate(PageRoutes.plan);
  }

  const onSubmit = async () => {
    logEvent('Drugs Added', {
      rxcuis: drugs.map(convertPreferredDrugInput),
      location: 'Drug optimizer',
    });
    await saveQuote(coverage!, zip!, countyName!, drugs);
    navigate(
      dataIsNotFull || !state.drugs.length ? PageRoutes.help : PageRoutes.offers
    );
  };

  const handleDrugsChange = (payload: PreferredDrugOutput[]) => {
    dispatch({
      type: ActionType.SET_DRUGS,
      payload,
    });
  };

  const onSkipClick = async () => {
    dispatch({
      type: ActionType.SET_DATA_IS_NOT_FULL,
      payload: true,
    });
    await saveQuote(coverage!, zip!, countyName!, drugs);
    navigate(PageRoutes.help);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: 1 }}>
      <Typography variant={'h3'} align={'center'}>
        Enter any prescription drugs you need covered by your plan.
      </Typography>

      <DrugSelection value={drugs} onChange={handleDrugsChange} />

      <V3InfoPanel
        title={'Why?'}
        text={
          'The cost of specific prescription drugs can vary by plan. We’ll show you all your options.'
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant={'outlined'} onClick={() => navigate(-1)}>
          Back
        </Button>
        <LoadingButton
          variant={'contained'}
          loading={loading}
          disabled={!drugs.length}
          color={'primary'}
          onClick={onSubmit}
        >
          Continue
        </LoadingButton>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledTextButton
          loading={loading}
          variant={'text'}
          color={'primary'}
          onClick={onSkipClick}
        >
          I can't find my drugs
        </StyledTextButton>
      </Box>
    </Box>
  );
}

const StyledTextButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiLoadingButton-loadingIndicator': {
    color: theme.palette.primary.main,
  },
}));
