import { CRModal } from '@coverright/ui/shared';
import { Box, IconButton, Typography } from '@mui/material';
import { CheckCircle, X } from '@phosphor-icons/react';
import * as React from 'react';
import { useRequestUrgentCall } from '@coverright/data-access/enrollment';
import {
  ProfileSource,
  UrgentCallTypeDto,
} from '@coverright/data-access/types/enrollment';
import { useLogEvent } from '@coverright/shared/analytics';
import { useContext, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createLoginUrl, KeycloakContext } from '@coverright/shared/keycloak';
import { CreateAccountForm } from '@coverright/widgets';

interface UrgentCallModalProps {
  open: boolean;
  onClose: () => void;
}

enum ModalMode {
  Entry,
  ContactInfo,
  ReceivedRequest,
}

export default function UrgentCallModal(props: UrgentCallModalProps) {
  const [requestUrgentCall] = useRequestUrgentCall();
  const { keycloak } = useContext(KeycloakContext);
  const [modalMode, setModalMode] = React.useState<ModalMode>(ModalMode.Entry);
  const callType = useRef<UrgentCallTypeDto | null>(null);
  const logEvent = useLogEvent();
  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (props.open) {
      logEvent('modal_open', {
        element_title: 'When can we call you?',
        element_id: 'urgent-call-modal',
      });
    } else {
      setModalMode(ModalMode.Entry);
    }
  }, [props.open]);

  const submit = () => {
    if (callType.current) {
      setLoading(true);
      requestUrgentCall({ variables: { urgentCallType: callType.current } })
        .then(() => {
          setModalMode(ModalMode.ReceivedRequest);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleCallTypeChange = (urgentCallType: UrgentCallTypeDto) => {
    callType.current = urgentCallType;
    if (!keycloak?.authenticated) {
      setModalMode(ModalMode.ContactInfo);
    } else {
      submit();
    }
  };

  return (
    <CRModal
      BackdropProps={{
        sx: {
          background: 'rgba(0, 0, 0, 0.25)',
        },
      }}
      width={480}
      paperPadding={'32px'}
      open={props.open}
      data-test="urgent-call-modal"
      onClose={props.onClose}
    >
      <>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'flex-end' }}>
          <IconButton
            size="small"
            edge="start"
            data-test="modal-close-button"
            onClick={props.onClose}
          >
            <X size={32} color="#000" />
          </IconButton>
        </Box>
        {modalMode === ModalMode.Entry && (
          <>
            <Typography
              className={'epilogue fs-32 lh-40 semi-bold'}
              align={'center'}
            >
              When can we call you?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'center',
                mt: 3,
              }}
            >
              <LoadingButton
                loading={loading}
                id={'urgent-call-modal-this-morning-button'}
                fullWidth
                variant={'outlined'}
                color={'primary'}
                onClick={() =>
                  handleCallTypeChange(UrgentCallTypeDto.ThisMorning)
                }
              >
                This morning
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'center',
                mt: 3,
              }}
            >
              <LoadingButton
                loading={loading}
                id={'urgent-call-modal-this-afternoon-button'}
                fullWidth
                variant={'outlined'}
                color={'primary'}
                onClick={() =>
                  handleCallTypeChange(UrgentCallTypeDto.ThisAfternoon)
                }
              >
                This afternoon
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'center',
                my: 3,
              }}
            >
              <LoadingButton
                loading={loading}
                id={'urgent-call-modal-soon-button'}
                fullWidth
                variant={'contained'}
                color={'primary'}
                onClick={() =>
                  handleCallTypeChange(UrgentCallTypeDto.AsSoonAsPossible)
                }
              >
                As Soon As Possible
              </LoadingButton>
            </Box>
            <Typography className={'fs-18'} align={'center'}>
              Or call us now{' '}
              <Box
                component={'a'}
                href={'tel:+18889697667'}
                sx={{ color: '#1C434F', textDecoration: 'none' }}
              >
                (888) 969 7667 | TTY: 711
              </Box>
            </Typography>
          </>
        )}
        {modalMode === ModalMode.ContactInfo && (
          <>
            <Typography
              className={'epilogue fs-32 lh-40 semi-bold mb-24'}
              align={'center'}
            >
              Provide you info
            </Typography>

            <CreateAccountForm
              onLoadingChange={setLoading}
              columns={1.5}
              ref={formRef}
              signInLink={createLoginUrl(document.location.href)}
              onSuccess={submit}
              source={ProfileSource.DrugOptimizer}
              onError={console.error}
              showPhone
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                variant={'contained'}
                color={'primary'}
                onClick={() => formRef.current?.requestSubmit()}
                loading={loading}
                sx={{ maxWidth: 392, m: 'auto' }}
                fullWidth
              >
                Continue
              </LoadingButton>
            </Box>

            <Typography className={'fs-18 mt-24'} align={'center'}>
              Or call us now{' '}
              <Box
                component={'a'}
                href={'tel:+18889697667'}
                sx={{ color: '#1C434F', textDecoration: 'none' }}
              >
                (888) 969 7667 | TTY: 711
              </Box>
            </Typography>
          </>
        )}
        {modalMode === ModalMode.ReceivedRequest && (
          <>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                justifyContent: 'center',
                my: 3,
              }}
            >
              <CheckCircle size={56} color="#0B741C" weight="duotone" />
            </Box>
            <Typography
              className={'epilogue fs-24 lh-40 semi-bold'}
              align={'center'}
            >
              Thanks!
              <br />A licensed agent will reach out to you.
            </Typography>
          </>
        )}
      </>
    </CRModal>
  );
}
