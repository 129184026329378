import React, { useState } from 'react';
import { useMedicarePlans, usePDPPlans } from '@coverright/data-access/medicare';
import { Field, PdpField, PdpSort, Sort } from '@coverright/data-access/types/medicare';
import { GetPlansYear } from '@coverright/utils';
import {Coverage} from "../lib";

interface Plan {
  bidId: string,
  planName?: string | null,
  monthlyPremium?: string | null
}

const page = {
  size: 1000,
  page: 0
};

export function usePlans(type?: Coverage, zip?: string, countyName?: string, carrier?: string): [Plan[], boolean] {
  const [getMaPlans, maPlansData] = useMedicarePlans()
  const [getPdpPlans, pdpPlansData] = usePDPPlans()

  const [plans, setPlans] = useState<Plan[]>([]);

  const getPlans = async () => {
    if (zip && countyName && carrier) {
      setPlans([]);

      const filter = {
        countyName,
        zip,
        companies: [carrier],
        planYear: GetPlansYear(),
      };


      if (type === Coverage.MA) {
        const result = await getMaPlans({variables: {
            page,
            sort: [{direction: Sort.Asc, field: Field.Name}],
            filter
          }}).then(res => res.data?.plans.data || []);
        setPlans(result)
      }
      if (type === Coverage.PDP) {
        const result = await getPdpPlans({variables: {
            page,
            sort: [{direction: PdpSort.Asc, pdpField: PdpField.Name}],
            filter
          }}).then(res => res.data?.PdpPlans.data || []);
        setPlans(result)
      }
    }
  }

  React.useEffect(() => {
    getPlans();
  }, [zip, countyName, carrier]);

  return [plans, maPlansData.loading || pdpPlansData.loading];
}
