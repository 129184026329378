import {  Route, Routes, Navigate } from 'react-router-dom';
import * as React from 'react';
import ZipForm from './pages/zip-form';
import Plan from './pages/plan';
import Drugs from './pages/drugs';
import Main from './pages/main';
import Offers from './pages/offers';
import HelpWanted from "./pages/help-wanted";
import { OnboardingProgressCard } from '@coverright/ui/shared';
import { OffersProgress } from './components/offers-progress';

export enum PageRoutes {
  main = '/',
  zip = '/zip',
  plan = '/plan',
  drugs = '/drugs',
  offers = '/offers',
  help = '/help-wanted',
}

export const routes = {
  main: {value: PageRoutes.main, component: Main},
  zip: {value: PageRoutes.zip, component: ZipForm},
  plan: {value: PageRoutes.plan, component: Plan},
  drugs: {value: PageRoutes.drugs, component: Drugs},
  offers: {value: PageRoutes.offers, component: Offers},
  help: {value: PageRoutes.help, component: HelpWanted},
}

export default function Router() {
  return <Routes>
    <Route
      path={routes.offers.value}
      element={<Offers />}
    />
    <Route
      path={routes.help.value}
      element={<HelpWanted />}
    />
    <Route path={'/'} element={<OnboardingProgressCard progress={<OffersProgress />} />}>
      <Route
        path={''}
        element={<Main />}
      />
      <Route
        path={routes.zip.value}
        element={<ZipForm />}
      />
      <Route
        path={routes.plan.value}
        element={<Plan />}
      />
      <Route
        path={routes.drugs.value}
        element={<Drugs />}
      />
    </Route>
    <Route element={<Navigate to={routes.main.value} />} />
  </Routes>
}
