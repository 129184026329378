import { useNavigate } from 'react-router';
import React from 'react';
import { Box, Button, Card, styled } from '@mui/material';
import { PageRoutes } from '../router';
import AgentCard from '../components/agent-card';
import OffersCtaCard from '../components/offers-cta-card';
import { Calendly } from '@coverright/features';
import { getStoredUser } from '@coverright/utils';

export default function HelpWanted() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        gap: 3,
        width: 1,
      }}
    >
      <Content>
        <div>
          <Button
            variant={'outlined'}
            startIcon={<img src={'/assets/img/arrow-left.svg'} />}
            onClick={() => navigate(PageRoutes.main)}
          >
            Update your information
          </Button>
        </div>

        <Card sx={{ p: 4 }}>
          <Calendly maxWidth={'unset'} client={getStoredUser()} />
        </Card>
      </Content>

      <Side>
        <OffersCtaCard />
        <AgentCard />
      </Side>
    </Box>
  );
}

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  flex: 1,
});

const Side = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  [theme.breakpoints.up('lg')]: {
    maxWidth: 294,
  },
}));
