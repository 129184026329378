import {createContext, useReducer} from "react";
import * as React from "react";
import {Action, drugOffersReducer, DrugOffersState} from ".";

const storedState = sessionStorage.getItem('drugOffersState');

const initialState: DrugOffersState = storedState ? JSON.parse(storedState) : {
  dataIsNotFull: false,
  carrier: '',
  plan: '',
  drugs: [],
}

export const DrugOffersContext = createContext<{
  state: DrugOffersState,
  dispatch: (action: Action) => void
}>({
  state: initialState, dispatch: () => {
  }
});

export const withDrugOffersContext = (WrappedComponent: any) => (props: any) => {
  const [state, dispatch] = useReducer<typeof drugOffersReducer>(drugOffersReducer, initialState)

  return (
    <DrugOffersContext.Provider value={{state, dispatch}}>
      <WrappedComponent {...props} />
    </DrugOffersContext.Provider>
  )
}

