import { MenuItem } from '@mui/material';
import { SelectInput } from '@coverright/ui/inputs';
import React from 'react';
import { useCompanies } from '../hooks/use-companies';
import {Coverage} from "../lib";

interface Props {
  zip?: string,
  countyName?: string,
  type?: Coverage,
  value: string,
  onChange: (value: string) => void
}

export function CompanySelect({type, zip, countyName, onChange, value}: Props) {
  const [companies, loading] = useCompanies(type, zip, countyName)

  return <SelectInput
    fullWidth
    hideTick
    loading={loading}
    className={'mb-0'}
    data-test={'carrier'}
    label={'Insurance Carrier'}
    value={value}
    placeholder={'Select carrier'}
    onChange={(event) => {
      onChange(event.target.value as string);
    }}
  >
    {companies.map(c => <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>)}
  </SelectInput>
}
