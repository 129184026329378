import { useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Progress } from '@coverright/ui/shared';
import { PageRoutes } from '../router';

export const OffersProgress = () => {
  const location = useLocation();

  const current = useMemo(() => {
    switch (location.pathname) {
      case PageRoutes.main: return 1;
      case PageRoutes.zip: return 2;
      case PageRoutes.plan: return 3;
      case PageRoutes.drugs: return 4;
      default: return 0;
    }
  }, [location.pathname]);

  return <Progress total={4} current={current} />
}
