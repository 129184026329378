import { PreferredDrugOutput } from '@coverright/widgets';

export enum Coverage {
  MA = 'MA',
  PDP = 'PDP',
}

export interface DrugOffersState {
  coverage?: Coverage;
  zip?: string;
  countyName?: string;
  state?: string;
  quoteId?: string;
  clientId?: string;
  plan: string;
  carrier: string;
  dataIsNotFull: boolean;
  drugs: PreferredDrugOutput[];
}

export enum ActionType {
  SET_COVERAGE = 'SET_COVERAGE',
  SET_LOCATION = 'SET_LOCATION',
  SET_CARRIER = 'SET_CARRIER',
  SET_PLAN = 'SET_PLAN',
  SET_DRUGS = 'SET_DRUGS',
  SET_QUOTE_ID = 'SET_QUOTE_ID',
  SET_CLIENT_ID = 'SET_CLIENT_ID',
  SET_DATA_IS_NOT_FULL = 'SET_DATA_IS_NOT_FULL',
}

export type Action =
  | SetQuoteIdAction
  | SetClientIdAction
  | SetDrugsAction
  | SetPlanAction
  | SetCarrierAction
  | SetCoverageAction
  | SetDataIsNotFullAction
  | SetLocationAction;

export type SetCoverageAction = {
  type: ActionType.SET_COVERAGE;
  payload: Coverage;
};

export type SetLocationAction = {
  type: ActionType.SET_LOCATION;
  payload: {
    zip?: string;
    countyName?: string;
    state?: string;
  };
};

export type SetPlanAction = {
  type: ActionType.SET_PLAN;
  payload: string;
};

export type SetQuoteIdAction = {
  type: ActionType.SET_QUOTE_ID;
  payload: string;
};

export type SetClientIdAction = {
  type: ActionType.SET_CLIENT_ID;
  payload: string;
};

export type SetCarrierAction = {
  type: ActionType.SET_CARRIER;
  payload: string;
};

export type SetDataIsNotFullAction = {
  type: ActionType.SET_DATA_IS_NOT_FULL;
  payload: boolean;
};

export type SetDrugsAction = {
  type: ActionType.SET_DRUGS;
  payload: PreferredDrugOutput[];
};
