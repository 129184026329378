import React from 'react';
import { Box } from '@mui/material';
import * as _ from 'lodash';

export function Progress({current, total}: {total: number, current: number}) {
  return React.useMemo(() => {
    return <Box sx={{display: 'flex', gap: 2, mb: 4, justifyContent: 'center'}}>
      {_.fill(Array(total), 1)
        .map((v, i) => (
          <Box key={i}
               sx={{
                 width: 64,
                 height: 8,
                 borderRadius: '8px',
                 backgroundColor: i < current ? '#1C434F' : '#D9D9D9'
               }} />
        ))}
    </Box>;
  }, [current, total])
}

