import { AppBar as CRAppBar } from '@coverright/shared/ui';
import { Box } from '@mui/material';
import { Phone } from '@phosphor-icons/react';
import * as React from 'react';
import { ScheduleACallButton } from '@coverright/features';

export function AppBar() {
  const logo = (
    <Box
      component={'img'}
      sx={{ mr: 2 }}
      src={'assets/img/Logo.svg'}
      width={132}
      height={36}
      className={'pointer'}
      onClick={() => (document.location.href = 'https://coverright.com/')}
    />
  );

  return (
    <CRAppBar
      variant={'light'}
      slots={{
        logo,
        right: <Right />,
        drawerContent: <ScheduleACallButton />,
      }}
    />
  );
}

const Right = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box
        sx={{
          display: { xs: 'none', lg: 'flex' },
          ml: { xs: '43px', xl: '100px' },
          gap: { xs: '32px', xl: '48px' },
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: { xs: '43px', xl: '88px' },
            gap: 1,
          }}
        >
          <Phone size={24} color="1C434F" weight="fill" />
          <Box
            component={'a'}
            href={'tel:+18882915580'}
            sx={{
              fontSize: 16,
              lineHeight: '19px',
              fontWeight: 500,
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              textDecoration: 'none',
              color: '#1C434F',
            }}
          >
            (888) 969-7667 | TTY: 711
          </Box>
        </Box>
      </Box>

      <ScheduleACallButton />
    </Box>
  );
};
