import React from 'react';
import {Box, Button, styled, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {PageRoutes} from '../router';
import {useLogEvent} from '@coverright/shared/analytics';
import {V3InfoPanel} from '@coverright/ui/shared';
import {ActionType, Coverage, DrugOffersContext} from "../lib";

export function Main() {
  const navigate = useNavigate();
  const {state: {coverage}, dispatch} = React.useContext(DrugOffersContext);
  const logEvent = useLogEvent();

  const onSubmit = (payload: Coverage) => {
    dispatch({
      type: ActionType.SET_COVERAGE,
      payload,
    });
    logEvent('Drug optimizer - select coverage', {coverage})

    navigate(PageRoutes.zip)
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
      <Typography variant={'h3'} align={'center'}>Welcome!<br />Let’s see if you can save money on your prescription drug cost</Typography>

      <ButtonContainer sx={{flexDirection: {xs: 'column', md: 'row'}}}>
        <StyledButton  data-test='pdp-coverage' variant={'outlined'} onClick={() => onSubmit(Coverage.PDP)} >
          <img src={'/assets/img/no-drugs.svg'} />
            Standalone<br />Drug Plan
        </StyledButton>

        <StyledButton data-test='ma-coverage' variant={'outlined'} onClick={() => onSubmit(Coverage.MA)} >
          <img src={'/assets/img/medicare-advantage.svg'} />
          Medicare<br />Advantage
        </StyledButton>
      </ButtonContainer>

      <V3InfoPanel sx={{mt: '19px'}}
                 title={'Not sure?'}
                 text={'If you are on Original Medicare (including if you have a Medicare Supplement plan) you will likely have a standalone prescription drug plan.  If you have a Medicare Advantage, your drug coverage is typically bundled within your plan.'} />

    </Box>
  );
}

export default Main;

const StyledButton = styled(Button)({
  height: 188,
  flexDirection: 'column',
  gap: 2,
  width: '100%',
  maxWidth: 180
})

const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16
})
